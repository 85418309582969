var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _vm.treeShow
            ? _c("CommonTree", {
                attrs: {
                  treeData: _vm.treeData,
                  defaultProps: _vm.defaultProps,
                  searchTitle: _vm.searchTitle,
                  treeTitle: "组织架构",
                  isShowdig: false,
                  showCheckbox: false,
                  "default-expand-all": true,
                  expandOnClickNode: false,
                  currentNodeKey: _vm.currentNodeKey,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                  nodeKey: "id",
                },
                on: { getNodeClick: _vm.treeNodeClick },
              })
            : _vm._e(),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "境内项目人员信息",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-submit": _vm.headSubmit,
                  "head-reportView": _vm.headReportView,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        row.dataState != "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdie(row)
                                  },
                                },
                              },
                              [_vm._v(" 填报\n            ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              this.type == "edit"
                ? "境内项目人员信息编辑"
                : this.type == "view"
                ? "境内项目人员信息查看"
                : "境内项目人员信息新增",
            "append-to-body": true,
            "close-on-click-modal": false,
            visible: _vm.isFormDialog,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isFormDialog = $event
            },
            close: _vm.domesticDialog,
          },
        },
        [
          _vm.isFormDialog
            ? _c("formDialog", {
                ref: "overseasProjectFillingEdit",
                attrs: { formData: _vm.formData },
                on: { domesticDialog: _vm.domesticDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }